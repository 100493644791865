export enum ApiResponse {
    success = 'success',
    failed = 'failed',
    error = 'error',
    customError = 'Some error occured.',
    valid = 'VALID',
    invalid = 'INVALID',
    userNotFoundErr = 'User not found',
    CHARGED = 'CHARGED',
    PENDING_VBV = 'PENDING_VBV'
}
export enum ApiStatusCodes {
    successStatusCode = 200,
    notFoundCode = 404,
    redirectionStatusCode = 301,
}
